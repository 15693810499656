.App {
  text-align: center;
  overflow-x: hidden;
  min-height: 100vh; /* will cover the 100% of viewport */
  display: block;
  position: relative;
  padding-bottom: 50px; /* height of your footer */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bg-light {
  background-color: white !important;
  border-bottom: 1px solid #22222225;
}

.dados {
  letter-spacing: 0.3px;
  color: silver;
}

.menuItem {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 30px;
  display: inline-flex;
  align-items: center;
  color: #1d1d1d !important;
  opacity: 0.9;
}

.subheadline {
  text-align: left;
  margin-bottom: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: silver;
}

.listgroup {
  text-align: left;
}

.listgroup ul {
  margin: 0 auto;
}

.listgroup p {
  font-size: 16px;
  margin-left: 15px;
}

.list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.list strong {
  margin-left: 5px;
}

.icons {
  color: grey;
  opacity: 0.9;
}

.menuItem span {
  margin-left: 6px;
}

.menuItem:hover {
  text-decoration-color: white !important;
}

.form-label {
  color: grey;
  font-size: 12px;
  text-transform: uppercase;
}

.btn-primary {
  background-color: #ff5660;
  border-color: #ff5660;
}

.btn-link {
  color: #ff5660;
}

.btn-link:hover {
  color: #e75e67;
}

.btn-primary:hover {
  background-color: rgb(231, 80, 90);
  border-color: rgb(231, 80, 90);
}

.btn-primary:active {
  background-color: #ff5660;
  border-color: #ff5660;
}

.btn-primary:focus {
  background-color: #ff5660;
  border-color: #ff5660;
}

.btn-primary:disabled {
  background-color: silver;
  border-color: silver;
}

.headline {
  margin: 20px auto;
  text-align: left;
  max-width: 550px;
}

.container {
  margin-right: auto; /* 1 */
  margin-left: auto; /* 1 */

  max-width: 960px; /* 2 */

  padding-right: 10px; /* 3 */
  padding-left: 10px; /* 3 */
}

.subSection {
  background: white;
  padding: 15px 0 25px 0;
  max-width: 100%; /* 2 */
}

.silverBg {
  background: #f7f7f7 !important;
}

.list-group li {
  font-size: 16px;
}

.comofunciona {
  text-align: left;
  max-width: 450px;
}

.subSection h2 {
  margin: 0 !important;
  margin-bottom: 20px !important;
  color: #1d1d1d;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 24px;
  color: silver;
  font-weight: 200 !important;
}

.subSection button {
  margin-bottom: 20px;
}

.subSection h4 {
  margin-top: 15px;
  margin-bottom: 10px;
}

.subSection small {
  color: silver;
}

.whiteBg {
  background: #f7f7f7;
}

.animationMobile {
  display: none;
}

.buttonMobile {
  display: none;
}

.moises {
  width: 150px;
  max-width: 100%;
  margin: 0px 20px 20px 20px;
  height: auto;
}

.bird {
  width: 170px;
  max-width: 100%;
  margin: 0px 20px 20px 20px;
  height: auto;
}

.enlink {
  width: 70px;
  max-width: 100%;
  margin: 0px 20px 20px 20px;
  height: auto;
}

.fuze {
  width: 50px;
  max-width: 100%;
  margin: 0px 20px 20px 20px;
  height: auto;
}

.equipe h4 {
  color: silver;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.footer {
  background: #1d1d1d;
  text-align: center;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footerItems {
  font-size: 12px;
  color: silver;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.footerItems span {
  opacity: 0.5;
  margin-left: 8px;
  margin-right: 8px;
}

.footerItems a {
  color: white !important;
}

.socialmedia {
  display: inline-flex;
}

.socialmedia a {
  margin-right: 10px;
}

.voluntarioHeadline {
  text-align: left;
}

.login button {
  background: #ff5660 !important;
}

.login button:hover {
  background: #e94f59 !important;
}

@media only screen and (max-width: 890px) {
  .mobilecenter {
    text-align: center !important;
  }
  .mobilehide {
    display: none;
  }

  .voluntarioHeadline p {
    margin-top: 15px;
    text-align: center;
  }

  .comofunciona {
    text-align: center;
    max-width: 100%;
  }

  .footerItems {
    font-size: 10px;
  }
  .moises {
    width: 120px;
    max-width: 60%;
  }

  .bird {
    width: 120px;
    max-width: 60%;
  }

  .enlink {
    width: 40px;
    max-width: 60%;
  }

  .fuze {
    width: 40px;
    max-width: 60%;
  }

  h1 {
    font-size: 2em;
  }

  .container {
    margin-top: 5px;
  }
  .headline {
    text-align: center;
  }

  .animationDesktop {
    display: none;
  }

  .buttonDesktop {
    display: none;
  }

  .animationMobile {
    display: block;
  }

  .buttonMobile {
    margin-top: 50px;
    display: block;
  }
}

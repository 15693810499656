html,
body {
  background-color: #f7f7f7 !important;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #ff5660 !important;
}

a.nav-link {
  color: #1d1d1d !important;
}

a:hover {
  text-decoration: none !important;
  opacity: 0.9;
}

.jumbotron {
  background-color: #f7f7f7 !important;
}

hr {
  opacity: 0.8;
}

p {
  font-size: 16px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: silver !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: silver !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: silver !important;
}
.css-1wa3eu0-placeholder {
  color: silver !important;
}

iframe {
  margin: 0px;
  padding: 0px;
  height: 650px;
  border: none;
}

iframe {
  display: block;
  width: 100%;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
}
